/* If the screen size is 768px wide or less, hide the element */
@media only screen and (max-width: 200px) {
  div.horizontal-container {
    display: none;
  }
  div.coming-soon {
    display: flex;
  }
}

html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-y: hidden;
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;
}

#root {
  width: 100vw;
  height: 100vh;
}

h2 {
  font-weight: bold;
  font-size: 24px;
  margin: 0px;
}

.coming-soon {
  display: none;
  width: 100%;
  height: 100%;
  margin-top: 50px;
  text-align: center;
  justify-content: space-evenly;
  background-color: #dc3545;
  color: #fff;
}

.coming-soon-heading {
  font-weight: 600;
  font-size: 2rem;
  margin: auto auto;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
  width: max-content;
  height: 100%;
}

.vertical-centre {
  margin: auto 0px;
}

.horizontal-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

/*
 * Sidebar
 */

.sidebar {
  z-index: 1;
  position: relative;
  width: max-content;
  color: #5a6268;
  background-color: #ffffff;
  font-weight: bold;
  font-size: 1.75rem;
}
::-webkit-scrollbar {
  width: 0px;
}

.sidebar div .floorplan_tab:hover, .sidebar div .design_tab:hover, .sidebar div .items_tab:hover, .sidebar div .help_tab:hover {
  background-color: #aaaaaa;
  color: #fff;
}

.sidebar .active {
  background-color: #0B2342;
  color: #ffffff;
}

hr {
  border-color: #dc3545;
  border-width: 2px;
  border-style: solid;
  width: 25%;
  text-align: left;
  margin: 0px 0px 10px 30px;
  border-radius: 2px;
}

.small-underline {
  width: 40px;
  margin: 5px 0;
}

.small-underline-right {
  width: 40px;
  margin: 0 20px 10px auto;
  text-align: right;
}

#context-menu {
  display: none;
  position: absolute;
  top: 61.5px;
  left: 44px;
  z-index: 2;
  width: 15%;
  height: max-content;
  font-weight: normal;
  font-size: 16px;
  color: #5a6268;
  background-color: #ffffff;
}

.context-menu-container {
  margin: 0px 20px;
}

#texture-context-menu, #color-context-menu {
  display: none;
  position: absolute;
  width: 12.8%;
  /* top: 61.5px; */
  top: 223.5px;
  left: 410px;
  z-index: 2;
  margin: 0px 20px;
  padding-bottom: 50px;
  font-weight: normal;
  font-size: 16px;
  color: #5a6268;
  background-color: #ffffff;
}
::-webkit-scrollbar {
  width: 0px;
}

.panel {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 600;
  border-color: #5a6268;
  border-width: 2px;
  border-style: solid;
  padding: 5px 30px;
  color: #5a6268;
}

.texture-panel {
  font-weight: 600;
  color: #5a6268;
}

.lead {
  display: block;
  margin-bottom: 5px;
  font-size: 1.25rem;
  font-weight: 600;
  color: #5a6268;
  width: 100%;
  padding: 2px 20px;
}

.outline-button {
  border-radius: 0;
}

.form-group {
  margin-bottom: 0.5rem;
  color: #5a6268;
}

small {
  color: #5a6268;
}

.control-label {
  font-size: 14px;
}

.form-control {
  width: 100px;
  height: max-content;
  padding: 0.2rem 0.75rem;
}

/* [type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect 
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0.3rem;
  top: 0.1rem;
  width: 1.1em;
  height: 1.1em;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #fff;
  border-radius: 2px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
/* checked mark aspect 
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: "\2713\0020";
  position: absolute;
  top: 0.13em;
  left: 0.4em;
  font-size: 1.2em;
  line-height: 0.8;
  color: #5A6268;
  transition: all 0.2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
}
/* checked mark aspect changes 
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
} */

.texture-tiles {
  width: 100%;
  height: 160px;
  overflow-y: overlay;
}

.texture-tiles::-webkit-scrollbar {
  width: 5px;
}

.texture-tiles::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 5px;
}

/* #texture-context-container {
  position: absolute;
  top: 61.5px;
  left: 44px;
  z-index: 2;
  width: 25%;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; 
} */
::-webkit-scrollbar {
  width: 0px;
}

#floorTexturesDiv {
  background-color: #ffffff;
  position: absolute;
  top: 61.5px;
  left: 44px;
  z-index: 2;
  width: 25%;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

#wallTextures {
  background-color: #ffffff;
  position: absolute;
  top: 61.5px;
  left: 44px;
  z-index: 2;
  width: 25%;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.texture-panel {
  margin-bottom: 5px;
  font-weight: 600;
  padding: 5px 20px;
  color: #5a6268;
}

.texture-panel-heading {
  font-size: 16px;
}

.texture-select-thumbnail {
  display: inline-block;
  z-index: 999;
  width: 6rem;
  /* min-width: 100px; */
  margin: auto;
}

.texture-select-thumbnail:hover {
  cursor: pointer;
}

.thumbnail {
  width: 100%;
  height: 5rem;
  border-radius: 10px;
  padding: 2px;
  object-fit: cover;
}

/*
 * Main content
 */

#loading-modal {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(50, 50, 50, 0.9);
}


#loading-modal.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading-modal span {
  text-align: center;
  color: #fff;
}

.right-container {
  z-index: 1;
  flex: 1 1 auto;
}

#camera-controls-top {
  position: absolute;
  top: 10%;
  right: 0;
  padding: 0 20px;
  text-align: right;
}

#camera-controls-bottom {
  position: absolute;
  bottom: 3%;
  right: 0;
  padding: 0 20px;
  text-align: right;
  z-index: 9999;
}

.basic-button {
  padding: 3px 8px 6px 8px;
  text-align: center;
  margin: 1px;
  background-color: #FFFFFF !important;
  border: none;
  color: #000000 !important;
  width: 40px;
  height: 40px;
  box-shadow: none !important;
}

.basic-button:hover {
  background-color: #0B2342 !important;
  color: #FFFFFF !important;
}

.icon-active {
  background-color: #0B2342 !important;
  color: #FFFFFF !important;
}

.icon-active .control-icon path{
  fill: #FFFFFF !important;
}

.basic-button:hover .control-icon path{
  fill: #FFFFFF;
}

.basic-button .control-icon path {
  fill: #0B2342;
}

.circle-button {
  background-color: #FFECEE;
  border-radius: 50%;
}

.lean-button {
  padding: 0px;
  text-align: center;
  margin: 1px;
}

.file-button {
  display: inline-block;
  cursor: pointer;
  padding: 3px 8px 6px 8px;
}

label {
  margin-bottom: 0px;
}

.vertical-controls-container {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-basis: fit-content;
  width: fit-content;
  vertical-align: middle;
}

#floorplanner {
  display: none;
}

#floorplanner-controls {
  display: flex;
  align-items: center;
  height: 81px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #FFFFFF;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 50px 0;
}

#draw-walls-hint {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 5px 10px;
  z-index: 10;
  display: none;
}

.icon-text-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1px 8px 3px 8px;
  align-content: center;
  color: #0B2342 !important;
  background-color: #fff;
  border: none;
  height: 100% !important;
}

.icon-text-button:focus {
  box-shadow: none !important;
  outline: none !important;
}

.icon-text-button.disabled {
  width: max-content;
  border-radius: 10px;
  color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #0B2342;
}

.icon-text-button .move-icon {
  fill: #000;
}

.icon-text-button.disabled .move-icon {
  fill: #fff;
}

.icon-text-button .draw-icon {
  fill: #000;
}

.icon-text-button.disabled .draw-icon {
  fill: #fff;
}

.icon-text-button .trash-icon {
  fill: #000;
}

.icon-text-button.disabled .trash-icon {
  fill: #fff;
}


.icon-centre {
  margin: 2px 1px 1px 1px;
}

.text-centre {
  margin: 1px;
  padding-top: 2px;
  padding-left: 2px;
}

#items-container {
  position: absolute;
  display: none;
  top: 61.5px;
  left: 44px;
  z-index: 2;
  width: 25%;
  height: 100vh !important;
  background-color: #ffffff;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
} 

/* ADD ITEMS */
#add-items {
  width: 100%;
  height: 100%;
  justify-content: start;
  /* padding-bottom: 100px; */
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  width: 0px;
}

.item-card {
  width: 43%;
  margin: 10px;
  display: inline-block;
  border: 4px solid #fff;
  border-radius: 0;
  box-shadow: 10px 10px 5px #aaaaaa;
  transition: 0.1s all ease-in-out;
}

.item-card:hover {
  transform: scale(1.05);
  box-shadow: 15px 15px 10px #aaaaaa;
  cursor: pointer;
}

.item-card-img {
  width: 100%;
  height: 10rem;
  object-fit: fill;
}

.item-card-title-outer {
  padding: 6px;
  background-color: #000000;
  width: 75%;
  margin: auto 0 auto auto;
}

.item-card-title {
  font-size: 0.6rem;
  background-color: #000000;
  padding: 0.1rem 0.3rem;
  border-color: #fff;
  border-width: 2px;
  border-style: dashed;
  text-align: center;
}

.active-title {
  border-style: solid;
}

.item-card-title:hover {
  border-style: solid;
}

.item-card-body {
  padding: 0;
}

/*
 * Top Bar
 */
.top-bar {
  z-index: 1;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
}

.company-logo {
  display: inline-flex;
}

.company-name {
  display: inline-flex;
  color: #0B2342;
  margin: auto 10px;
  border-color: #0B2342;
  border-width: 2px;
  border-style: solid;
  padding: 5px 10px;
}

.company-name span {
  font-weight: bold;
  font-size: 24px;
  margin: 0px;
}

.top-bar-logo {
  width: 3rem;
  height: 100%;
  padding: 0.4rem;
}

.top-bar-login-button {
  /* margin-top: 10px;
  margin-right: 30px; */
  border-style: none;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  color: #5a6268;
}

.top-bar-login-button:active,
.top-bar-login-button:focus {
  border-style: none;
  outline: none;
  box-shadow: none;
}

.name-display {
  margin: auto 5px;
  border-color: #5a6268;
  border-width: 2px;
  border-style: dashed;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  color: #5a6268;
}

.custom-light-button {
  background-color: #fff;
  font-size: 1.5rem;

  border-style: none;
  outline: none;
  box-shadow: none;
}

.custom-light-button:hover {
  border-style: none;
  outline: none;
  box-shadow: none;
}

.custom-light-button:focus,
.custom-light-button:focus {
  border-style: none;
  outline: none;
  box-shadow: none;
}

.login-modal {
  margin: auto;
  display: flex;
  flex-direction: column;
  min-width: 400px;
}

.login-modal-heading {
  margin: 1px;
  padding-top: 5px;
  padding-left: 2px;
}

.login-modal-tab {
  width: 40%;
  text-align: center;
  margin: auto;
}

.login-form {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  width: 100%;
}

.signup-form {
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  width: 100%;
}

.login-form-input-space {
  display: block;
  /* flex-direction: row; */
  margin: auto 5px;
  width: 100%;
}

.login-form-icon {
  display: flex;
  padding: 0.55rem 0.7rem;
  margin: auto;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
}

.login-form-input {
  padding: 5px 15px;
  height: 45px;
}

.signup-form-input {
  height: 30px;
}

.modal-plain-text {
  margin: 5px;
  font-weight: 600;
}

.small-alert {
  font-size: 0.7rem;
  margin: 5px 5px 0 5px;
  padding: 2px;
  padding-left: 0.4rem;
}

.large-alert {
  font-size: 0.9rem;
  margin: 5px 5px 0 5px;
  padding: 2px;
  padding-left: 0.4rem;
}

.login-submit-button, .save-button {
  width: 9.4rem;
  height: 2.5rem;
  font-weight: 600;
  border-radius: 10px;
  background-color: #0B2342;
  color: #ffffff;
  border: none
}

.login-submit-button:hover, .save-button:hover {
  background-color: #ffffff;
  color: #0B2342;
}

.login-submit-button:hover .right-arrow path {
  fill: #0B2342;
}

.nav-pills .nav-link {
  background-color: #ffffff;
  color: #5a6268;
  font-weight: 600;
  padding-bottom: 5px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #ffffff;
  color: #5a6268;
  font-weight: 600;
  border-bottom: 4px #0B2342 solid;
  border-radius: 0;
}

.save-file-space {
  width: 100%;
}

.save-file-input {
  width: 90%;
  height: 45px;
  border: none;
  border-radius: 5px;
  background: #F2F2F2;
}

.save-file-input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.save-file-container {
  width: 100%;
}

.save-file-list-block {
  display: inline-block;
}

.save-file-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 200px;
  max-width: 400px;
  margin: 5px;
  padding: 0.4rem;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
}

.save-file-list-item:hover {
  border-width: 1px;
  border-style: solid;
  border-color: #5a6268;
  cursor: pointer;
}

.save-file-name {
  margin: auto 1px;
}

.info-text {
  margin-top: 0.6rem;
  font-size: 0.8rem;
}

.center-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100vh;
  overflow-y: scroll;
}

.fw-600 {
  font-weight: 600;
}

.login-card {
  width: 30% !important;
  border: none;
}

.login-card .card-body {
  border-radius: 10px;
  background: #EEE;
}

@media (max-width: 575.98px) { 
  .login-card {
    width: 100% !important;
  }
}

/* .w-40 {
  width: 40% !important;
} */

.search-form-control {
  width: 100% !important;
  border-radius: 20px;
  color: #C6C6C6;
  height: 2.5rem;
  padding: 0.2rem 0.75rem 0.2rem 2rem !important;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #F9F9F9;
}

.ps-r {
  position: relative;
}

.ps-a {
  position: absolute;
}

.load-more-btn {
  background-color: #0B2342 !important;
  color: #ffffff !important;
  border: none !important;
  transition: none;
}

.load-more-btn:hover {
  cursor: pointer;
  background-color: #ffffff !important;
  color: #0B2342 !important;
  border: none !important;
  box-shadow: none !important;
}

.loading-modal {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  /* background-color: rgba(50, 50, 50, 0.9); */
  /* background-color: #FFFFFF; */
  /* font-size: 2.5rem; */
}

.loading-modal.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-modal span {
  font-size: 16px;
  text-align: center;
  color: #000000;
}

.nav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
}

.tabs-container {
  padding: 0.9rem;
  width: 85% !important;
  display: grid;
}

.tabs-container .nav {
  overflow: auto;
  white-space: nowrap;
  display:  block !important;
}

.tabs-container .nav .nav-item {
  display: inline-block;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scroll-icon {
  cursor: pointer;
  font-size: 24px;
}

.texture-active {
  border: solid;
  border-color: red;
  /* border-radius: 0.5rem; */
}

.texture-panel-heading {
  margin-bottom: 5px;
  font-weight: 600;
  padding: 5px 15px;
  color: #5a6268;
}

.landscape {
  width: 100%;
  height: 100%;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.design_tab, .floorplan_tab, .items_tab, .help_tab {
  font-size: 22px;
  color: #0B2342;
}

.items_tab div svg, .help_tab div svg {
  font-size: 22px;
}

.help_tab {
  position: absolute;
  width: 100%;
  bottom: 7px;
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.password-input-container input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.password-input-container input[type="password"]::-ms-reveal,
.password-input-container input[type="password"]::-webkit-inner-spin-button,
.password-input-container input[type="password"]::-webkit-outer-spin-button {
  display: none;
}

#apply-texture, #apply-wall-height, #apply-color {
  background-color: #0B2342;
  color: #ffffff;
}

#apply-texture:hover, #apply-wall-height:hover, #apply-color:hover {
  background-color: #0B2342;
  color: #ffffff;
}

.catagory-card {
  border: 0 !important;
  background: #FCFCFC !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.catagory-card .card-header{
  border: 0 !important;
  background: #FCFCFC !important;
}

.texture-card {
  width: 100% !important;
  height: 100% !important;
  overflow-y: scroll;
  border: 0 !important;
  background: #FCFCFC !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.texture-card .card-header{
  border: 0 !important;
  background: #FCFCFC !important;
}

.catagory-container {
  display: flex !important;
  align-items: baseline !important;
  background-color: #FFFFFF;
}

.catagory-wrapper {
  flex: 1;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: unset !important;
  font-size: 14px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.header {
  border-bottom: 1px solid lightgray;
}

.catagory-wrapper::-webkit-scrollbar {
  width: 0px;
}

.catagory-wrapper::-webkit-scrollbar-thumb {
  display: none;
}

.catagory-wrapper .nav-item {
  white-space: nowrap !important;
}
.catagory-wrapper .nav-link {
  color: #000000 !important;
}

.catagory-wrapper .nav-link.active {
  color: #FFFFFF !important;
  background-color: #0B2342 !important;
}

.catagory-wrapper .nav-link.highlighted {
  border-bottom: 2px solid #0B2342;
}

.arrow-btn {
  font-size: 20px;
  border: none !important;
  background-color: transparent !important;
}

.arrow-btn svg path {
  fill: #000000;
}

.arrow-btn:disabled svg path {
  fill: #8E98A4 !important;
}

.productList-card {
  position: absolute !important;
  width: 100%;
  padding-bottom: 5rem;
}

.search-icon {
  color: #384350;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
}

.primary-text {
  color: #0B2342 !important;
}

.dropdown-container .dropdown-toggle::after {
  content: none; /* Hide the default arrow */
  margin-left: 8px; /* Adjust as needed */
}

.dropdown-container .dropdown-toggle svg {
  vertical-align: middle; /* Align the SVG icon vertically */
  margin-bottom: 2px; /* Adjust as needed */
}

.drop-down-menu {
  border-radius: 10px;
  background: #FFF;
  box-shadow: -2px 2px 10px 0px rgba(115, 115, 115, 0.12);
}

.fs-14 {
  font-size: 14px;
}

.preview-container {
  width: max-content;
}

.preview-container .card {
  border: none !important;
}

.preview-card-img  {
  width: 301px;
  height: 163px;
  border: none !important;
  border-radius: 5px;
  background: linear-gradient(180deg, rgba(11, 35, 66, 0.00) 0%, rgba(25, 53, 90, 0.20) 100%);
  box-shadow: 0px 4px 30px 0px rgba(121, 118, 118, 0.12);
}

.product-card-img  {
  width: 301px;
  height: 163px;
  border: none !important;
  border-radius: 5px;
  background: linear-gradient(180deg, rgba(11, 35, 66, 0.00) 0%, rgba(25, 53, 90, 0.20) 100%);
  box-shadow: 0px 4px 30px 0px rgba(121, 118, 118, 0.12);
}

.product-img  {
  width: 301px;
  height: 163px;
}

.preview-text {
  color: #8D8D8D;
}

.fs-20 {
  font-size: 20px !important;
}

.fw-700 {
  font-weight: 700;
}

.black-text {
  color: #000000;
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.fw-400 {
  font-weight: 400;
}

/* YourComponent.css */
.dots-menu-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #D9D9D9;
}

.dots-menu-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #333;
  border-radius: 50%;
  margin: 2px;
}

.more-menu {
  width: max-content;
  position: absolute;
  bottom: -24%;
  right: -15%;
  z-index: 3;
  padding: 12px;
  background-color: #fff;
  border: 1px solid #ccd8e0;
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0,0,0,.25);
  transform: translate(0,15px) scale(.95);
  transition: transform .1s ease-out,opacity .1s ease-out;
}

.more-menu-caret {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 18px;
  height: 10px;
  float: left;
  overflow: hidden;
}

.more-menu-caret-outer {
  border-bottom: 10px solid #c1d0da;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: auto;
}

.more-menu-caret-inner {
  top: 1px;
  left: 1px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #fff;
  position: relative;
  z-index: 1; 
}

.more-menu-caret-outer, .more-menu-caret-inner {
  position: absolute;
  display: inline-block;
  margin-left: -1px;
  font-size: 0;
  line-height: 1;
}

.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.preview-card-img-container {
  position: relative;
}

.product-card-img-container {
  position: relative;
}

.open-button {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  width: 169px;
  height: 40px;
  background-color: #0B2342;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.dashboard-container .header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 5;
}

.preview-card-img-container:hover .open-button {
  display: block;
}

.product-card-img-container:hover .open-button {
  display: block;
}

.copy-alert, .save-alert{
  color: #0EAB15 !important;
  background: #CCFECE !important;
  padding: 5px !important;
}

.select-alert {
  width: max-content !important;
  color: #0EAB15 !important;
  background: #CCFECE !important;
  padding: 5px !important;
  margin-top: 3.5rem;
}

.edit-alert {
  color: #FFFFFF !important;
  background: #dc3545 !important;
  padding: 5px !important;
}

.create-project-btn {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #0B2342 !important;
}

.create-project-btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.dashboard-container .project-name {
  display: none;
}

#dashboard-loader {
  z-index: 1051;
}

.dotted-line {
  display: inline-block;
  border-bottom: 3px dotted #000;
  padding-bottom: 5px;
}

.header-logout {
  text-decoration: none !important; /* Remove underline */
  color: inherit !important; /* Inherit the color from the parent */
}

.header-logout {
  background-color: transparent !important;
}

.save-close-btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.logo {
  width: 150.45px;
  height: 54.703px;
}

.fs-32 {
  font-size: 32px;
}

.fs-18 {
  font-size: 18px;
}

.save-btn, .close-btn, .share-btn {
  height: 30px;
  border: 2px solid #0B2342;
  border-radius: 7px;
  background-color: #FFFFFF;
  color: #0B2342;
}

.save-btn:active, .save-btn:hover, .close-btn:active, .close-btn:hover, .share-btn:active, .share-btn:hover {
  background-color: #0B2342 !important;
  color: #FFFFFF !important;
  border-color: transparent !important;
}

.save-btn:focus, .close-btn:focus, .share-btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.save-btn:hover .save-icon path, .close-btn:hover .close-icon path, .share-btn:hover .share-icon path {
  fill: #FFFFFF !important;
}

.save-btn .save-icon path, .close-btn .close-icon path, .share-btn .share-icon path {
  fill: #0B2342 !important;
}

.powered-by-logo {
  position: absolute;
  bottom: -10px;
}

#close-modal .modal-content {
  display: flex;
  justify-content: center;
  width: 25rem;
  border-radius: 10px;
  background: #FCFCFC;
}

.modal-save-button {
  width: 85px;
  font-weight: 600;
  background-color: #0B2342;
  color: #ffffff;
  border: 2px solid #0B2342;
  border-radius: 7px;
}

.modal-save-button:hover, .modal-close-button:hover {
  background-color: #5a6268;
  color: #ffffff;
}

.modal-close-button {
  width: 85px;
  border: 2px solid #0B2342;
  border-radius: 7px;
  background-color: #FFFFFF;
  color: #0B2342;
}

.login-btn {
  width: 6.5rem;
  height: 2rem;
  font-weight: 600;
  background-color: #0B2342;
  color: #ffffff;
  border: none;
}

.login-btn:hover {
  background-color: #ffffff;
  color: #0B2342;
}

.login-btn:hover .login-icon path {
  fill: #0B2342 !important;
}

.login-btn:active {
  background-color: #0B2342 !important;
  color: #FFFFFF !important;
  border-color: transparent !important;
}

.signup-submit-button {
  width: 9.4rem;
  height: 2.5rem;
  border-radius: 10px;
  font-weight: 600;
  background-color: #FFFFFF;
  color: #0B2342;
  border: none
}

.signup-submit-button:hover {
  background-color: #0B2342;
  color: #FFFFFF;
}

.signup-submit-button:hover .right-arrow path {
  fill: #FFFFFF;
}

.signin-text {
  color: #0B2342;
  text-decoration-line: underline;
}

.signin-text:hover {
  color: #0B2342;
}

.share-box{
  width: 80%;
  border: 1px solid darkgray;
  text-align: center;
}

.image-container {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  background: #ffffff;
  height: 100%;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 85vh;
  object-fit: contain;
  margin-top: 2vh;
}

.download-container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.download-title {
  justify-self: center;
}

.download-close-btn {
  justify-self: end;
}

.cls-1 {
  fill: url(#linear-gradient-2);
}

.cls-2 {
  fill: #fff;
}

.cls-3 {
  fill: url(#linear-gradient-5);
}

.cls-4 {
  fill: url(#linear-gradient-3);
}

.cls-5 {
  fill: url(#linear-gradient);
}

.cls-6 {
  fill: url(#linear-gradient-4);
}

.camera-btn-bs {
  box-shadow: none !important;
}

.product-title {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#help-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 85vw;
  height: 85vh;
  background: #ffffff;
  margin-top: 30px;
  overflow: hidden;
}

.help-contents {
  width: 100%;
  height: 100%;
}

.help-close-btn {
  position: absolute;
  right: 10px;
}

.help-close-btn svg path  {
  fill: #FFFFFF;
}

.zoom-controls {
  background-color: #FFFFFF;
}

#control-area {
  position: relative;
  width: 100px; /* Size of the control area */
  height: 100px;
  border-radius: 50%;
  background: #FFF; /* Background of the control area */
  border: 1px solid #FFF; /* Border of the control area */
  user-select: none; /* Prevents text selection */
  touch-action: none; /* Prevents default touch behavior */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#move-button {
  position: absolute;
  width: 40px; /* Size of the move button */
  height: 40px;
  border-radius: 50%;
  background: #888; /* Background of the move button */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #0B2342;
  font-weight: bold;
  font-size: 12px;
  user-select: none;
}
.arrow {
  position: absolute;
  width: 0; 
  height: 0; 
  border-style: solid;
  user-select: none;
}
.up-arrow {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #0B2342 transparent;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}
.down-arrow {
  border-width: 10px 10px 0 10px;
  border-color: #0B2342 transparent transparent transparent;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}
.left-arrow {
  border-width: 10px 10px 10px 0;
  border-color: transparent #0B2342 transparent transparent;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
}
.right-arrow_1 {
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #0B2342;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
}

#items_count_container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  text-align: center;
  /* background-color: rgba(50, 50, 50, 0.9); */
}

#items_count {
  display: flex;
  align-items: center;
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  width: max-content;
  padding: 10px;
}

.total-items-text {
  margin-left: 5px;
  font-size: 24px;
}

.items_count_loader {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.help_text {
  font-size: 12px;
  display: flex;
}

#wall-context-menu {
  display: none;
  position: absolute;
  width: 12.8%;
  top: 61.5px;
  left: 410px;
  z-index: 2;
  margin: 0px 20px;
  padding-bottom: 50px;
  font-weight: normal;
  font-size: 16px;
  color: #5a6268;
  background-color: #ffffff;
}

@media (max-width: 575.98px) {
  .login-btn {
    font-size: 12px !important;
  }

  .login-icon {
    width: 15px;
    height: 15px;
  }

  .powered-by-logo svg {
    width: 80px;
    height: 80px;
  }

  #control-area {
    width: 90px;
    height: 90px;
    right: -10px;
  }

  #move-button {
    width: 35px;
    height: 35px;
    font-size: 10px !important;
  }
  .up-arrow {
    border-width: 0 4px 4px 4px;
    top: 10%;
  }
  .down-arrow {
    border-width: 4px 4px 0 4px;
    bottom: 10%;
  }
  .left-arrow {
    border-width: 4px 4px 4px 0;
    left: 10%;
  }
  .right-arrow_1 {
    border-width: 4px 0 4px 4px;
    right: 10%;
  }

  .basic-button {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  .basic-button svg {
    width: 20px;
    height: 20px;
  }
  
  #camera-controls-top {
    padding: 0 10px;
  }

  .design_tab, .floorplan_tab, .items_tab div svg, .help_tab div svg {
    font-size: 13px;
  }

  /* #items_tab div svg, #help_tab div svg {
    width: 13px;
    height: 13px;
  } */

  #items-container {
    width: 45%;
    left: 30px;
  }

  .arrow-btn {
    font-size: 10px;
  }

  .catagory-wrapper {
    font-size: 10px;
  }

  .item-card {
    width: 85%;
    margin: 10px;
  }

  #context-menu {
    left: 30px;
    width: 58%;
  }

  #floorTexturesDiv, #wallTextures {
    left: 30px;
    width: 45%;
  }

  #help-container {
    width: 91vw;
    height: 90vh;
    margin: 30px 0 0 15px;
  }

  .search-form-control {
    height: 1.5rem;
  }

  #texture-context-menu, #wall-context-menu, #color-context-menu {
    left: 182px;
    margin: 0px 10px;
    padding-bottom: 25px;
  }

  .texture-panel-heading {
    font-size: 12px;
  }

  .control-label {
    font-size: 12px;
  }

  .form-control {
    font-size: 12px;
  }

  #apply-texture, #apply-wall-height, #apply-color {
    font-size: 12px;
  }

  .productList-card .form-group div svg {
    width: 12px;
    height: 12px;
  }

  .help_text {
    font-size: 8px;
  }
}

@media (min-width: 575.98px) and (max-width: 992px) {
  #items-container {
    width: 35%;
    top: 47.5px;
    left: 39px;
  }

  #context-menu {
    width: max-content;
    top: 47.5px;
    left: 40px;
  }

  #floorTexturesDiv, #wallTextures {
    width: 35%;
    top: 47.5px;
    left: 39px;
    overflow-y: scroll;
  }

  .loading-modal span{
    font-size: 1.5rem;
  }

  .pencil-icon, .compass-icon, .plus-icon, 
  .redo-icon, .load-icon {
    font-size: 1rem;
  }

  /* .sidebar {
    width: 40px;
  } */

  .company-name span, .name-display, .top-bar-login-button {
    font-size: 0.7rem;
  }

  #context-menu-name {
    font-size: 1rem;
    margin: auto;
  }

  #context-menu-delete {
    width: 85%;
    margin: auto;
  }

  .panel {
    padding: 5px 15px;
  }

  .panel-heading {
    font-size: 0.8rem;
  }

  .control-label {
    font-size: 12px;
  }

  .form-control {
    height: 30px;
  }

  .texture-tiles {
    height: 110px;
  }

  #texture-context-menu, #color-context-menu {
    font-size: 14px;
    top: 201.5px;
    left: 289px;
  }

  #wall-context-menu {
    font-size: 14px;
    top: 47.5px;
    left: 289px;
  }

  .design_tab, .floorplan_tab, .items_tab div svg, .help_tab div svg {
    font-size: 18px;
  }

  .login-card {
    width: 50% !important;
  }

  #Layer_1 {
    width: 90px;
    height: 30px;
  }

  #camera-controls-top {
    top: 20%;
    padding: 0 8px;
  }

  .basic-button {
    width: max-content;
    height: max-content;
  }

  .basic-button .control-icon {
    width: 16px !important;
    height: 16px !important;
  }

  .item-card {
    width: 43%;
    margin: 5px;
  }

  .productList-card {
    padding: 0.25rem;
    padding-bottom: 3rem;
  }

  .share-btn, .save-btn, .close-btn {
    font-size: 12px;
    width: max-content;
  }

  .arrow-btn {
    font-size: 16px;
  }

  .catagory-wrapper {
    font-size: 12px;
  }

  #help-container {
    width: 80vw;
    height: 80vh;
    margin: 0;
  }

  .help-close-btn {
    right: 5px;
  }

  #camera-controls-bottom {
    right: 50px;
    padding: 0;
  }

  #move-left svg, #move-right svg {
    width: 15px;
    height: 25px;
  }

  #move-up svg, #move-down svg {
    width: 25px;
    height: 15px;
  }

  #ellipse svg {
    width: 20px;
    height: 20px;
  }

  #control-area {
    width: 90px;
    height: 90px;
  }

  #move-button {
    width: 40px;
    height: 40px;
    font-size: 10px;
  }

  .up-arrow {
    border-width: 0 5px 5px 5px;
    top: 10%;
  }
  .down-arrow {
    border-width: 5px 5px 0 5px;
    bottom: 10%;
  }
  .left-arrow {
    border-width: 5px 5px 5px 0;
    left: 10%;
  }
  .right-arrow_1 {
    border-width: 5px 0 5px 5px;
    right: 10%;
  }

  .powered-by-logo svg {
    width: 70px;
    height: 70px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .login-card {
    width: 50% !important;
  }

  #texture-context-menu, #wall-context-menu, #color-context-menu {
    left: 393px;
  }

  .item-card {
    width: 40%;
  }

  #context-menu {
    width: max-content;
  }

  .productList-card {
    padding: 0.25rem;
    padding-bottom: 4rem;
  }

  #items-container {
    width: 35%;
  }

  #floorTexturesDiv, #wallTextures {
    width: 35%;
  }

  .arrow-btn {
    font-size: 16px;
  }

  .catagory-wrapper {
    font-size: 12px;
  }
}